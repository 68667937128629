import 'rsuite/dist/rsuite.min.css'
import '../styles/app.scss'
import React, { useEffect, useMemo, useState } from 'react'
import { ApolloProvider } from '@apollo/client'
import { appWithTranslation } from 'next-i18next'
import { withToken } from '../services/client'
import store from '../app/store'
import { Provider } from 'react-redux'
import { setCredentials } from '../features/auth/authReducer'
import { applicationLoaded } from '../features/app/appReducer'
import { CURRENT_USER_WITH_COMPANY } from '../services/graphql/queries/user'
import { useRouter } from 'next/router'
import { ToastContainer } from 'react-toastify'
import GoogleTagmanager from '../components/GoogleTagmanager'
import UserCentrics from '../components/UserCentrics'
import TrustpilotScript from '../components/TrustpilotScript'
import Head from 'next/head'

const MyApp = ({ Component, pageProps }) => {
  const router = useRouter()
  // Because we use SSR this is kinda tricky. Local storage is not available on the server side.
  // So we have to wait until the client side is loaded to get the token from local storage.
  // After that, ApolloClient is using the withToken client instead of the normal which allows us to perform
  // queries that require authentication.
  const [token, setToken] = useState('')
  const withTokenClient = useMemo(() => withToken(token, router.locale), [token])
  const resetUser = () => {
    localStorage.removeItem('token')
    store.dispatch(setCredentials({
      me: null,
      token: ''
    }))
  }

  useEffect(() => {
    if (router.query.ref) {
      localStorage.setItem('ref', router.query.ref)
    }

    /**
     * Set bootstrap & application related stuff
     */
    if (!store.getState().app.hasLoaded) {
      // Load bootstrap initially so proper.js for dropdowns works
      import('bootstrap').then(bootstrap => {
        window.bootstrap = bootstrap

        // We do this because it seems like Next.js is not tree shaking
        // This means if we always import bootstrap, the build will be huge
        // If you want to use bootstrap use useEffect + this store to access it
        store.dispatch(applicationLoaded(true))
      })
    }

    /**
     * The order is important here. If the user is impersonated, we need to check for the impersonation token first
     * Otherwise, the user is probably logged in, and we can check for the normal token
     * @type {string}
     */
    const storedToken = localStorage.getItem('impersonation')
      ? localStorage.getItem('impersonation')
      : localStorage.getItem('token')

    if (storedToken) {
      setToken(storedToken)

      withToken(storedToken, router.locale)
        .query({ query: CURRENT_USER_WITH_COMPANY, fetchPolicy: 'no-cache' })
        .then(({ data }) => {
          if (!data?.currentUser) {
            resetUser()
            return
          }

          store.dispatch(setCredentials({
            me: data.currentUser,
            token: storedToken
          }))
        })
        .catch(resetUser)
    }
  }, [router.pathname])

  return (
    <>
      <Head>
        {
          router.locales.map(locale => (
            <link
              key={locale}
              rel="alternate"
              hrefLang={locale}
              href={`${process.env.NEXT_PUBLIC_FRONTEND_URL}${locale === 'en' ? '' : `/${router.locale}`}${router.asPath.split('?')[0]}`}
            />
          ))
        }
      </Head>
      <ToastContainer />
      <UserCentrics />
      <GoogleTagmanager />
      <TrustpilotScript />
      <Provider store={store}>
        <ApolloProvider client={withTokenClient}>
          <Component {...pageProps} />
        </ApolloProvider>
      </Provider>
    </>
  )
}

export default appWithTranslation(MyApp)
