import { ApolloClient, InMemoryCache, HttpLink, from } from '@apollo/client'
import { createUploadLink } from 'apollo-upload-client'
import { setContext } from '@apollo/client/link/context'

const getLocale = path => {
  const language = path.replace('/', '').split('/')

  return language.length > 0 ? ['en', 'de'].includes(language[0]) ? language[0] : 'en' : 'en'
}

// For easy to use client stuff when needed in ssr for instance
export const client = (locale = '', uri = process.env.NEXT_PUBLIC_BACKEND_URL + 'graphql') => {
  if (typeof window !== 'undefined' && !locale) {
    locale = getLocale(window.location.pathname)
  }

  return new ApolloClient({
    cache: new InMemoryCache(),
    link: from([
      setContext((_, { headers }) => {
        return {
          ...headers,
          'Accept-Language': locale
        }
      }),
      new HttpLink({ uri })
    ])
  })
}

// More advanced client to access guarded routes
export const withToken = (token, locale = '', uri = process.env.NEXT_PUBLIC_BACKEND_URL + 'graphql') => {
  if (typeof window !== 'undefined' && !locale) {
    locale = getLocale(window.location.pathname)
  }

  return new ApolloClient({
    link: from([
      setContext((_, { headers }) => {
        return {
          headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : '',
            'Accept-Language': locale
          }
        }
      }),
      createUploadLink({ uri })
    ]),
    cache: new InMemoryCache()
  })
}
