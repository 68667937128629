import Script from 'next/script'

export default function TrustpilotScript () {
  return (
    <>
      <Script
        type="text/javascript"
        src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
        async
      />
    </>
  )
}
