import Script from 'next/script'

export default function UserCentrics () {
  return (
    <>
      <Script
        id="usercentrics-cmp"
        src="https://app.usercentrics.eu/browser-ui/latest/loader.js"
        data-settings-id="YpW3vqLGKYBGoS"
        strategy="afterInteractive"
      />
    </>
  )
}
