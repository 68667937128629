import { configureStore } from '@reduxjs/toolkit'
import authReducer from '../features/auth/authReducer'
import appReducer from '../features/app/appReducer'

export default configureStore({
  reducer: {
    auth: authReducer,
    app: appReducer
  }
})
