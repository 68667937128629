import { createSlice } from '@reduxjs/toolkit'

export const authReducer = createSlice({
  name: 'auth',
  initialState: {
    me: null,
    token: ''
  },
  reducers: {
    setCredentials: (state, { payload: { me, token } }) => {
      state.me = me
      state.token = token
    },
    resetCredentials: (state) => {
      state.me = null
      state.token = ''
    }
  }
})

export const selectMe = (state) => state.me
export const selectIsAuthenticated = (state) => !!state.token

// Action creators are generated for each case reducer function
export const { setCredentials, resetCredentials } = authReducer.actions

export default authReducer.reducer
