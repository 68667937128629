import { createSlice } from '@reduxjs/toolkit'

export const appReducer = createSlice({
  name: 'app',
  initialState: {
    hasLoaded: false
  },
  reducers: {
    applicationLoaded: (state, { payload }) => {
      state.hasLoaded = payload
    }
  }
})

// Action creators are generated for each case reducer function
export const { applicationLoaded } = appReducer.actions

export default appReducer.reducer
